import * as React from "react";
import Image from "next/image";

import { cn } from "@/lib/cn";

type SquareImageProps = {
  className: string;
  style: React.CSSProperties;
  rotate?: boolean;
};

/**
 * Creates the square boxes for the Work section
 *
 * @param className - The class name for the square.
 * @param style - The style for the square.
 * @param rotate - Whether the square should be rotated.
 */
const SquareImage: React.FC<SquareImageProps> = ({
  className,
  style,
  rotate,
}) => (
  <div className={className} style={style}>
    <Image
      loading="lazy"
      src="/images/square.svg"
      alt="square"
      height={96}
      width={96}
      className={rotate ? "-rotate-90" : ""}
    />
  </div>
);

const squarePositions = [
  {
    id: "square-1",
    className: "absolute -top-16 left-0 z-20 bg-gradient-to-bl",
    rotate: false,
  },
  {
    id: "square-2",
    className: "absolute left-10 top-32 z-20 bg-gradient-to-bl",
    rotate: false,
  },
  {
    id: "square-3",
    className: "absolute -top-52 left-24 bg-gradient-to-bl",
    rotate: false,
  },
  {
    id: "square-4",
    className: "absolute -top-16 right-0 z-20 bg-gradient-to-br",
    rotate: true,
  },
  {
    id: "square-5",
    className: "absolute right-10 top-32 z-20 bg-gradient-to-br",
    rotate: true,
  },
  {
    id: "square-6",
    className: "absolute -top-48 right-24 bg-gradient-to-br",
    rotate: true,
  },
];

const Squares = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        "vxs:block absolute inset-0 hidden h-1/2 w-full",
        props.className
      )}
    >
      {squarePositions.map((pos) => (
        <SquareImage
          key={pos.id}
          className={pos.className}
          style={{}}
          rotate={pos.rotate}
        />
      ))}
    </div>
  );
});

Squares.displayName = "Squares";

export default Squares;
