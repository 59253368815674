import * as React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import { HeroBg, Page } from "@/components";
import { Hero, Work } from "./components";
import { TransitionContext } from "@/contexts/TransitionContext";

/**
 * Renders the home page
 * Shows the hero section, work section and work together section
 */
const HomePage = () => {
  const container = React.useRef<HTMLDivElement>(null);

  const { timeline } = React.useContext(TransitionContext);

  useGSAP(
    () => {
      gsap.fromTo(container.current, { opacity: 0 }, { opacity: 1 });
      timeline.add(gsap.to(container.current, { opacity: 0 }));
    },
    { scope: container }
  );

  return (
    <div ref={container} className="relative">
      <Page pageTitle="Tarun Sharma • Frontend Developer">
        <HeroBg className="md:-top-[8%]" />
        <Hero />
        <Work />
      </Page>
    </div>
  );
};

export default HomePage;
