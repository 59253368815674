import * as React from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Image from "next/image";

/**
 * Component for the star image with animation
 */
const Star = ({ className }: { className: string }) => {
  const ref = React.useRef(null);

  useGSAP(() => {
    gsap.fromTo(
      ref.current,
      { scale: 0, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.75, delay: 0.225, ease: "power4.out" }
    );
  }, []);

  return (
    <div className={className} ref={ref}>
      <Image
        src="/images/star.svg"
        alt="star"
        height={70}
        width={70}
        loading="lazy"
        className="3xl:h-[85px] 3xl:w-[85px] xs:h-[50px] xs:w-[50px] will-change-transform sm:h-[70px] sm:w-[70px] 2xl:h-[80px] 2xl:w-[80px]"
      />
    </div>
  );
};

export default Star;
