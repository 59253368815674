import * as React from "react";
import Image from "next/image";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

/**
 * Component that renders a scroll indicator using GSAP.
 */
const ScrollIndicator2 = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ ...props }, ref) => {
  const arrowRef = React.useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.fromTo(
      arrowRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.7, delay: 0.375, ease: "power2.out" }
    );
  }, []);

  return (
    <div className={props.className} ref={ref}>
      <div ref={arrowRef}>
        <Image
          src="/images/scroll-arrow.svg"
          alt="scroll-indicator"
          width={45}
          height={45}
          className="xs:h-12 xs:w-12 animate-moveUpAndFade h-8 w-8"
        />
      </div>
    </div>
  );
});

ScrollIndicator2.displayName = "ScrollIndicator2";

export default ScrollIndicator2;
