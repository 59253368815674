import hirely from "../../../../public/images/hirely.webp";
import planitio from "../../../../public/images/planitio.webp";
import iaga from "../../../../public/images/iaga.webp";
import propbuying from "../../../../public/images/propbuying.webp";
import pintags from "../../../../public/images/pintags.webp";

/**
 * List of projects that are finished
 */
export const fineshedProjects = [
  {
    id: 0,
    title: "Hirely",
    description: "Single platform for hiring freelancers and professionals.",
    imageSrc: hirely,
    href: "https://hirely.tarunfy.com",
    backgroundColor: "#E11D48b0",
  },
  {
    id: 1,
    title: "Planit.io",
    description: "Meeting scheduling app made for teams and individuals alike.",
    imageSrc: planitio,
    href: "https://planitio.tarunfy.com",
    backgroundColor: "#2E6AECb0",
  },
  {
    id: 2,
    title: "IAGA",
    description:
      "Dynamic website, webapp and an admin panel for an animation training institute.",
    imageSrc: iaga,
    href: "https://iagainstitute.com",
    backgroundColor: "#19B69Db0",
  },
  {
    id: 3,
    title: "Propbuying",
    description: "Real estate website for buying and selling properties.",
    imageSrc: propbuying,
    href: "https://www.propbuying.com",
    backgroundColor: "#F37A20b0",
  },
];

/**
 * List of projects that are still in progress
 */
export const onGoingProjects = [
  {
    id: 1,
    title: "Pintags",
    description:
      "Bookmarking app for saving and organizing your favorite links.",
    imageSrc: pintags,
    href: "#",
    backgroundColor: "#0F0F0F",
  },
];
