import * as React from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import ScrollIndicator from "./components/scroll-indicator";
import Star from "./components/star";

/**
 * Renders the hero section of the website on the home page
 */
const Hero = () => {
  const containerRef = React.useRef(null);
  const heading1Ref = React.useRef(null);
  const heading2Ref = React.useRef(null);
  const paragraphRef = React.useRef(null);

  useGSAP(() => {
    const refs = [heading1Ref, heading2Ref, paragraphRef];

    refs.forEach((ref, index) => {
      gsap.fromTo(
        ref.current,
        { y: "100%" },
        {
          y: 0,
          duration: 1.5,
          delay: 0.075 * index,
          ease: "power4.out",
        }
      );
    });
  }, []);

  const containerStyles = `
    2xl:max-w-8xl 3xl:max-w-9xl 4xl:max-w-10xl 5xl:max-w-11xl relative mx-auto flex xs:min-h-[70vh] md:min-h-screen w-full max-w-full cursor-default flex-col items-center justify-center sm:max-w-[40rem] md:max-w-[50rem] lg:max-w-[63rem] xl:max-w-[75rem] min-h-[75vh] vs:min-h-[70vh] pt-32 mb-1 xs:mb-0 xs:pb-0 xs:pt-0
  `;

  return (
    <section className={containerStyles} ref={containerRef}>
      <div className="noise-overlay xs:!opacity-0 absolute inset-0 !z-0 h-full w-full !opacity-50" />
      <div className="xs:px-0 flex flex-col items-center justify-center gap-y-8 px-1.5 sm:gap-y-16">
        <div className="relative z-10">
          <Star className="xs:-left-10 xs:-top-12 absolute -left-32 -top-10 hidden sm:-left-16 sm:-top-14 md:-left-24 md:block 2xl:-left-36 2xl:-top-16" />
          <Star className="xs:-right-10 xs:-bottom-12 absolute -bottom-10 -right-32 hidden sm:-bottom-14 sm:-right-16 md:-right-24 md:block 2xl:-bottom-16 2xl:-right-36" />

          <div className="hero-heading overflow-hidden">
            <h1 className="hero-text" ref={heading1Ref}>
              Hi. I&apos;m Tarun.
            </h1>
          </div>
          <div className="hero-heading overflow-hidden px-0.5">
            <h1 className="hero-text" ref={heading2Ref}>
              A Developer.
            </h1>
          </div>
        </div>
        <div className="flex w-full items-center justify-center px-5 sm:px-10">
          <div className="hero-paragraph overflow-hidden">
            <p ref={paragraphRef} className="hero-paragraph-text">
              Building seamless, interactive web applications with a focus on
              performance and user experience.
            </p>
          </div>
        </div>
      </div>
      <div className="xs:bottom-10 xs:absolute relative mt-10 flex w-full items-center justify-center">
        <ScrollIndicator />
      </div>
      <div
        className="xs:hidden absolute bottom-0 left-0 right-0 z-[400] h-14
      bg-[linear-gradient(to_top,#110348_0%,#110348_40%,#1103480d_100%)]"
      />
    </section>
  );
};

export default Hero;
