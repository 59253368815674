import * as React from "react";
import Image, { StaticImageData } from "next/image";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { cn } from "@/lib/cn";

type ProjectCardProps = {
  title: string;
  description: string;
  image: StaticImageData;
  backgroundColor: string;
  href: string;
  isCompleted?: boolean;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  image,
  backgroundColor,
  href,
  isCompleted = true,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.fromTo(
      containerRef.current,
      {
        y: 30,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.75,
        ease: "power2.out",
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 90%",
        },
      }
    );
  }, []);

  return (
    <div
      ref={containerRef}
      className={cn(
        "vs:h-[310px] vxs:h-[350px] xs:h-[380px] group relative flex h-[280px] items-center justify-center rounded-3xl bg-[#1d067a3f] p-2 shadow-[0_40px_80px_#11044980] outline outline-[1px] outline-[#1d067d]/95 sm:h-[400px] md:h-[450px] lg:h-[600px]",
        {
          "pointer-events-none grayscale": !isCompleted,
        }
      )}
    >
      <div className="absolute inset-x-0 top-0 z-[10] mb-[-1px] h-[1px] bg-[linear-gradient(to_right,#0000_20%,#f2fff4_50%,#0000_80%)]" />
      <a
        href={href}
        target={isCompleted ? "_blank" : "_self"}
        className={cn(
          "ring-primary-50/20 lg:hover:ring-primary-50/50 active:cursor-active group relative h-full w-full cursor-pointer overflow-hidden rounded-3xl bg-[linear-gradient(190deg,#1d067a,#0d013c)] px-7 pt-8 ring-1 transition-all duration-500 ease-[cubic-bezier(.165,.84,.44,1)]",
          {
            "pointer-events-none grayscale": !isCompleted,
          }
        )}
      >
        <div className="absolute inset-x-0 top-0 z-[10] mb-[-1px] h-[1px] bg-[linear-gradient(to_right,#0000_20%,#f2f2ff_50%,#0000_80%)]" />
        <div
          className="absolute inset-0 z-0 opacity-100 transition-all duration-500 lg:opacity-0 lg:group-hover:opacity-100"
          style={{
            background: `radial-gradient(circle at 50% 0%, ${backgroundColor}, #0d013c)`,
          }}
        />
        <div className="relative z-10 flex w-full items-start justify-between">
          <div className="flex flex-col gap-y-2">
            <h1
              style={{ textShadow: "0 -2px 10px #fff3" }}
              className="text-primary-50 font-Acorn-Bold xs:text-3xl text-2xl tracking-wide"
            >
              {title}
            </h1>
            <p className="text-primary-200 text-sm xl:text-base">
              {description}
            </p>
          </div>
          <Image
            src="/images/scroll-arrow.svg"
            alt="arrow-right"
            width={32}
            height={32}
            className="ease-in-out-quad -rotate-90 transition-all duration-700 lg:group-hover:translate-x-[10px]"
          />
        </div>
        <Image
          src={image}
          alt={title}
          className="xs:mt-10 mx-auto mt-2.5 flex-1 translate-y-1 object-cover transition-all duration-500 sm:max-w-[90%] lg:mt-6 lg:group-hover:-translate-y-3"
        />
      </a>
    </div>
  );
};

export default ProjectCard;
