import * as React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import { fineshedProjects, onGoingProjects } from "./data";
import { Squares, ProjectCard } from "./components";

/**
 * Component that displays the work section.
 */
const Work = () => {
  const headingRef = React.useRef<HTMLHeadingElement>(null);
  const subHeadingRef = React.useRef<HTMLHeadingElement>(null);

  useGSAP(() => {
    const refs = [headingRef, subHeadingRef];

    refs.forEach((ref, index) => {
      gsap.fromTo(
        ref.current,
        { y: "100%" },
        {
          y: 0,
          duration: 1.5,
          delay: 0.075 * index,
          ease: "power4",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 85%",
            end: "bottom center",
          },
        }
      );
    });
  }, []);

  const containerStyles = `
    3xl:gap-y-24 4xl:gap-y-28 3xl:py-20 sm:px-5 relative mx-auto flex min-h-screen
    flex-col items-center justify-start gap-y-16 xs:pt-16 lg:pb-52 md:pb-40 sm:pb-32 pb-24 2xl:gap-y-20
  `;

  return (
    <section className={containerStyles}>
      <Squares />

      <div className="xs:max-w-screen-xs relative z-20 flex w-full flex-col gap-y-8 px-5 sm:max-w-screen-sm sm:gap-y-10 sm:px-0 md:max-w-screen-md md:gap-y-12 lg:max-w-screen-lg lg:gap-y-16">
        {fineshedProjects.map((project) => (
          <ProjectCard
            key={project.id}
            title={project.title}
            description={project.description}
            image={project.imageSrc}
            backgroundColor={project.backgroundColor}
            href={project.href}
          />
        ))}
      </div>

      {/* Ongoing Projects Section */}
      <div className="flex flex-col items-center justify-center gap-y-6">
        <div className="xs:py-3 xs:h-auto h-14 overflow-hidden text-center">
          <h1
            className="work-heading leading-[1.2] 2xl:leading-[1.3]"
            ref={headingRef}
          >
            In Progress.
          </h1>
        </div>
        <div className="hero-paragraph flex w-full items-center justify-center overflow-hidden px-5 sm:px-10">
          <p ref={subHeadingRef} className="hero-paragraph-text text">
            Work in various stages of completion, from concept to production.
          </p>
        </div>
      </div>

      <div className="xs:max-w-screen-xs relative z-20 flex w-full flex-col gap-y-16 px-5 sm:max-w-screen-sm sm:px-0 md:max-w-screen-md lg:max-w-screen-lg">
        {onGoingProjects.map((project) => (
          <ProjectCard
            key={project.id}
            title={project.title}
            description={project.description}
            image={project.imageSrc}
            backgroundColor={project.backgroundColor}
            href={project.href}
            isCompleted={false}
          />
        ))}
      </div>
    </section>
  );
};

export default Work;
